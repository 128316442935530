<template>
    <div @click.self="close_main_menu" v-if="show_menu" class="main-menu-container">
        <div class="main-menu slide-in-right"  id="main-menu">
            <UserMini/>
            <div class="btns">
                <div class="btn" v-if="role >= 99 && role != 105" @click="go_to('/admin')">
                    <i class="material-icons">settings</i>
                    <p>ניהול</p>
                </div>
                <div class="btn" v-if="role > 99 && role != 105" @click="go_to('/bi')">
                    <i class="material-icons">savings</i>
                    <p>BI</p>
                </div>
                <div class="btn" v-if="role >=24 && role != 105" @click="go_to('/applications')">
                    <i class="material-icons">apps</i>
                    <p>אפליקציות</p>
                </div>
                <div class="btn" v-if="role==105" @click="go_to('/yerakot')">
                    <i class="material-icons">apps</i>
                    <p>דהן ירקות</p>
                </div>
                <div class="btn" @click="go_to('/')">
                    <i class="material-icons">home</i>
                    <p>ראשי</p>
                </div>
                <div class="btn" @click="go_to('/catalog')">
                    <i class="material-icons">auto_stories</i>
                    <p>קטלוג</p>
                </div>
                <div class="btn" @click="go_to('/contact')">
                    <i class="material-icons">contact_phone</i>
                    <p>צור קשר</p>
                </div>
                <div class="btn" @click="go_to('/drushim')">
                    <i class="material-icons">person_search</i>
                    <p>דרושים</p>
                </div>
                <div class="btn" @click="go_to('/branches')">
                    <i class="material-icons">location_on</i>
                    <p>סניפים</p>
                </div>
                <router-link class="btn" to="/sales_catalog" target="_blank">
                    <i class="material-icons">photo_library</i>
                    <p>קטלוג מבצעים</p>
                </router-link>
                <!-- <router-link class="btn" to="/club" target="_blank">
                    <i class="material-icons">badge</i>
                    <p>הצטרפות למועדון</p>
                </router-link> -->
                <div class="btn">
                    <i class="material-icons">groups</i>
                    <p>אודות</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import store from '../../store'
import UserMini from '../menu/User_mini.vue';
import router from '../../router';

export default {
components:{UserMini},
setup(){
    
    const show_menu = ref(computed(() => {
        return store.getters.show_menu
    }))

    const role = ref(computed(() => {
        return store.getters.role
    }))
    const close_main_menu = () => {
        store.dispatch('toggle_menu');
    }

    const go_to = (path) => {
        router.push(path);
        store.dispatch('toggle_menu')
    }

    return{
        show_menu, role, go_to,close_main_menu
    }
}
}
</script>

<style scoped>
.main-menu-container{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.main-menu{
    margin-top: 60px;
    width: 375px;
    height: calc(100% - 60px);
    background: var(--secondary);
    border-bottom-left-radius: 60px;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.199);
    clip-path: inset(0px -10px 0px -10px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 120px calc(100% - 120px);
}
.btns{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    overflow-y: auto;
}
.btn{
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    user-select: none;
}
.btn:hover i{
    color: var(--yellow);
}
.btn:hover p{
    margin-right: 30px;
}
.btn i{
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: var(--light);
    transition: 0.25s;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
.btn p{
    margin-right: 15px;
    font-size: 24px;
    font-weight: 300;
    color: var(--light);
    transition: 0.25s;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.438);
}



.slide-in-right{
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.slide-out-right{
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

</style>