import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import PrimeVue from 'primevue/config';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/he';
import Button from "primevue/button";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import {projectAuth, projectFunctions} from './firebase/config';
import InstantSearch from 'vue-instantsearch/vue3/es';
import {alert} from './Methods/Msgs'
import {  createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'
 // "serve": "SET NODE_OPTIONS=--openssl-legacy-provider && vue-cli-service serve",
// "build": "SET NODE_OPTIONS=--openssl-legacy-provider && vue-cli-service build"
const metaManager = createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
});



projectAuth.onAuthStateChanged(async user => {
    store.state.loader = true;
    try{
        await store.dispatch("set_user", user);
        await store.dispatch("refresh_profile", user);
   
    }catch(err){
        alert('error','אירעה שגיאה',
        'ייתכן כי קישוריות האינטרנט שלך חלשה או לא פעילה!')
    }
    store.state.loader = false;
});

// projectFCM.getToken({ vapidKey: 'BNGSQOF4hOHH_pl2E9On1WEbJASDWUUwKAktf1UXtQGE--Q-QBdXw6o2znmjOa8i-o6bXqritUgMd_3l397o91w'})
// .then((currentToken)=>{
//     if (currentToken) {
//       store.state.push_token = currentToken;
//     } else {
//         console.log('No registration token available. Request permission to generate one.');
//     }
// }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
// })

// projectFCM.onMessage(function(payload) {
//     var title = payload.notification.title;
//     var options = {
//       body: payload.notification.body,
//       image:payload.notification.image,
//       icon:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/Products%2F1168_375x375.png?alt=media&token=f2dd0786-a036-4e7b-8ec6-6daf67b60af7',
//       data:{
//         time: new Date(Date.now()).toString(),
//         click_action:payload.data.click_action
//       }
//     };
  
//     var notification = new Notification(title, options);

//     notification.onclick = function(event) {
//       console.log(event);

//       window.open(event.target.data.click_action, '_system');
//       window.open(event.target.data.click_action, '_blank');
      
//     };
// });
 

document.addEventListener("visibilitychange", () => {
    let state = document.visibilityState;
    if(projectAuth.currentUser){
        projectFunctions.httpsCallable('visibility_state')({state: state})
        // projectFunctions.httpsCallable('generation_pdf')()
    }
})

createApp(App)
.component("Button", Button)
.use(store)
.use(router)
.use(PrimeVue)
.use(ElementPlus, {locale})
.use(InstantSearch)
.use(createMetaManager())
.use(metaPlugin)
.use(metaManager)
.mount('#app')

