<template>
    <div class="main-loader">
        <div class="logo-img">
            <img src="@/assets/logos/logo_192.png" alt="" id="ros-logo">
            <img src="@/assets/loader.gif" alt="">
        </div>
        <p class="main-title">Rosman Logistics LTD</p>
        <p class="sub-title heartbeat">טוען... אנא המתן</p>
    </div>
</template>

<script>
export default {
setup(){
    return{
    }
}
}
</script>

<style scoped>
.main-loader{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100000;
    color: whitesmoke;
    direction: rtl;
}
.logo-img{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 280px;
    background: white;
    border-radius: 50%;
    margin-bottom: 25px;
    overflow: hidden;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
}
.logo-img img{
    max-width: 100%;
    max-height: 100%;
}
#ros-logo{
    position: absolute;
    top: 10px;
    border-radius: 50%;
    width: 140px;
}
.sub-title{
    width: 100%;
    text-align: center;
    font-size: 2vh;
}
.main-title{
    width: 100%;
    text-align: center;
    font-size: 3vh;
    margin-bottom: 25px;
}
.heartbeat {
    animation-delay: 5s;
	-webkit-animation: heartbeat 2.5s ease-in-out infinite both;
	        animation: heartbeat 2.5s ease-in-out infinite both;
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
</style>