import { ref } from 'vue';
import { projectFirestore,projectStorage,projectAuth } from '../firebase/config';



const use_chat_messages_storage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
  
    // Path for firestore
    const filePath = ref(null);
  
    const uploadImage = async (file,user_display_name) => {
      filePath.value = `Chat_messages/${projectAuth.currentUser.uid}/${new Date().toLocaleDateString('he')}/${new Date().toLocaleTimeString('he')}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
        await store_in_db(user_display_name)
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };

    const store_in_db=async(user_display_name)=>{
        const doc = projectFirestore.collection('Chat').doc('Messages')
        .collection(projectAuth.currentUser.uid).doc()
        await doc.set({
            createdAt:new Date(),
            msgId:doc.id,
            name:user_display_name,
            image:url.value,
            image_path:filePath.value,
            type:'client'
        })
    }

    // Delete the file
    const deleteImage = async path => {
      const storageRef = projectStorage.ref(path);
  
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };


    return { error, url, filePath, uploadImage, deleteImage };
};

export default use_chat_messages_storage;