<template>
    <div v-if="!is_general" class="main-nav">
        <div class="menu-toggle" @click="open_main_menu">
            <i class="material-icons">menu</i>
        </div>
        <div class="top-btns">
            <div class="agents-portal" @click="go_to('/agents')">
                <p id="agents-portal-btn">פורטל סוכנים</p>
            </div>
            <!-- @click="toggle_chat" -->
            <div class="support-btn" @click="handle_support_btn">
                <i class="material-icons">support_agent</i>
            </div>
        </div>
        <div class="logo" @click="go_to('/')">
            <p>ROSMAN Logistics</p>
            <img src="@/assets/logos/logo_76.png" alt="">
        </div>
    </div>
    <div v-if="is_general" class="rosman-general">
        <div class="rosman-logo" @click="go_to('/branches')">
            <img :src="branche_logo" alt="רוסמן לוגו">
            <!-- <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2" alt=""> -->
        </div>
    </div>
</template>

<script>
import router from '../../router';
import store from '../../store';
import {projectAuth} from '../../firebase/config'
import {not_loging_msg} from '../../Methods/Msgs'
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import branche_logo from '../../components/Branches/img/branche_logo.jpg'


export default {
setup(){
    const is_general = ref(false)

    const handle_support_btn = ()=>{
        if(projectAuth.currentUser){
            toggle_chat()
        }else{
            not_loging_msg('משתמש לא מחובר','על מנת להתחבר לתמיכה אונליין, עליך להתחבר למערכת')
        }
        
    }
    const toggle_chat=()=>{
        store.state.show_support_chat = !store.state.show_support_chat
    }
    const open_main_menu = () => {
        store.dispatch('toggle_menu');
    }
    const go_to = (path) => {
        router.push(path)
    }
    
    watch(router.currentRoute,()=>{
        if(router.currentRoute.value.fullPath=='/branches' || router.currentRoute.value.fullPath=='/branches_map' || router.currentRoute.value.fullPath=='/sales_catalog'){
            is_general.value = true
        }else{
            is_general.value = false
        }
    })
     
    return{
        open_main_menu,
        go_to,
        toggle_chat,
        handle_support_btn,
        is_general,
        branche_logo
    }
}
}
</script>

<style scoped>
    .main-nav{
        direction: rtl;
        position: relative;
        width: 100%;
        height: 60px;
        background: var(--secondary);
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 60px calc(100% - 60px - 140px) 140px;
    }
    .rosman-general{
        width: 100%;
        height: 60px;
        background: black;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .rosman-logo{
        cursor: pointer;
        width: max-content;
        padding: 5px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-direction: row;
        align-items: center;
    }
    .logo{
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-direction: row;
        align-items: center;
    }
    .rosman-logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .logo img{
        max-width: 50%;
        max-height: 90%;
    }
    .logo p{
        width: auto;
        text-align: center;
        color: var(--yellow);
        text-shadow: 0 2px 3px rgba(15, 13, 13, 0.192);
    }
    .menu-toggle{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-toggle i{
        font-size: 60px;
        color: var(--light);
        text-shadow: 0 2px 3px rgba(15, 13, 13, 0.192);
        cursor: pointer;
    }
    .agents-portal{
        width: 120px;
        height: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #agents-portal-btn{
        background: var(--purple);
        padding: 10px;
        border-radius: 5px;
        color: var(--light);
        text-shadow: 0 1px 3px black;
        font-weight: 400;
        cursor: pointer;
    }
    .top-btns{
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .support-btn{
        margin-right: 5px;
        height: 100%;
        width: 50px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.92);
        cursor: pointer;
    }
    .support-btn i {
        font-size: 40px;
        text-shadow: 0 0 3px black;
        transition:  0.25s;
    }
    .support-btn:hover{
        color: var(--yellow);
    }
</style>