import { ref,computed } from "vue";
import store from '../store'


function getCssText(selector) {
    var cssText = '';
    var styleSheets = document.styleSheets;
    for (var i = 0; i < styleSheets.length; i++) {
        var styleSheet = styleSheets[i];
        console.log(styleSheet);
        var cssRules = styleSheet.cssRules;
        for (var j = 0; j < cssRules.length; j++) {
            var cssRule = cssRules[j];
            if (cssRule.selectorText === selector) {
                cssText += cssRule.cssText;
            }
        }
    }
    return cssText;
}

const user = ref(computed(()=>{
    return store.state.user
}))

const role = ref(computed(() => {
    return store.state.role
}))

const nutral_rosman_routes = (path) => {
   if(path.includes('shlush') || path.includes('computer-service') || path.includes('sales_catalog')) return true
   return false
}



export{
    getCssText,
    user,
    role,
    nutral_rosman_routes
}