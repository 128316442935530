import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/app-check';
// import 'firebase/firebase-messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBMit_XjNJRBf8xmcTJHJYyd-N3t8moW9s',
  authDomain: 'rosmannext.firebaseapp.com',
  projectId: 'rosmannext',
  storageBucket: 'rosmannext.appspot.com',
  messagingSenderId: '805472773639',
  appId: '1:805472773639:web:9a62e3281fe5d26a42eca2',
  measurementId: 'G-YWS6H3MXV1',
};

// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
const projectStorage = myApp.storage();
const projectFunctions = myApp.functions();

// projectFunctions.useEmulator("localhost", 5001);

const FireStore = firebase.firestore;
const projectDataBase = myApp.database();
const projectDb = firebase.database;
// const projectFCM = myApp.messaging();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  projectFirestore,
  projectStorage,
  projectAuth,
  timestamp,
  FireStore,
  projectFunctions,
  projectDataBase,
  projectDb,
  firebase,
};
