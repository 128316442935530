<template>
  <div class="home">
    <div class="title-home">
      <p class="name">Rosman Logistics</p>
      <p class="under-name">ייבוא  |  שיווק  |  מותגים  |  טכנולוגיה</p>
    </div>
    <div class="blob loc-1"></div>
    <div class="blob loc-2"></div>
    <div class="blob loc-3"></div>
    <div class="blob loc-4"></div>
    <div class="ros-logo">
        <img src="@/assets/logos/logo_192.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  setup(){


    return{

    }
  }
}
</script>
<style scoped>
.home{
  width: 100%;
  height: 100%;
  background-image: url('../assets/map.svg');
  background-size: cover;
  background-position: center;
}
.ros-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
}
.ros-logo img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
	animation: pulse 5s infinite;
}
.title-home{
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 375px;
  height: calc(50% - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.name{
  width: fit-content;
  text-align: center;
  font-size: 36px;
  color: whitesmoke;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.836);
  font-weight: 400;

}
.under-name{
  margin-top: 10px;
  width: fit-content;
  text-align: center;
  font-size: 18px;
  color: whitesmoke;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.836);
  font-weight: 400;
}
.blob{
	border-radius: 50%;
	margin: 10px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	background: rgba(255, 255, 255, 0.637);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

.loc-1{
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 1;
}
.loc-2{
  position: absolute;
  top: 150px;
  right: 100px;
  z-index: 1;
}
.loc-3{
  position: absolute;
  bottom: 150px;
  right: 130px;
  z-index: 1;
}
.loc-4{
  position: absolute;
  bottom: 200px;
  right: 45%;
  z-index: 1;
}


@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
	}
	
	50% {
		box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
	}

	75% {
		box-shadow: 0 0 0 100px rgba(255, 255, 255, 0);
	}
	
	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@media only screen and (max-width: 700px) {
  .no-mobile{
    display: none;
  }
}
</style>
