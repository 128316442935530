import { ElNotification } from "element-plus";
import Swal from 'sweetalert2'
import router from '../router/index'

const slide_pop_error = (text , duration = 2500) => {
    ElNotification({
        title: "שגיאה!",
        type: "error",
        message: text,
        showClose: false,
        position: 'top-left',
        duration: duration,
        zIndex: '10000000'
    });
}

const slide_pop_successs = (text, duration = 2500) => {
    ElNotification({
        title: "הצלחה",
        type: "success",
        message: text,
        showClose: false,
        duration: duration,
        position: 'top-left',
        zIndex: '10000000'
    });
}

const alert=(icon,title,text)=>{
    return Swal.fire({
        icon,
        title,
        text,
    })
}

const alert_confirm=(title,icon="question",iconHtml='?')=>{
    return Swal.fire({
        title,
        icon,
        iconHtml,
        confirmButtonText: 'כן',
        cancelButtonText: 'לא',
        showCancelButton: true,
        showCloseButton: true
      })
}

const not_loging_msg = (title,text)=>{
    Swal.fire({
        icon: 'info',
        title,
        text,
        confirmButtonText: 'התחבר עכשיו',
        denyButtonText: 'צור משתמש',
        showDenyButton: true,
        denyButtonColor: 'blue'
    }).then(res => {
        if(res.isConfirmed){
            router.push('/login')
        }
        if(res.isDenied){
            router.push('/register')
        }
    })
}



export{not_loging_msg,slide_pop_error, slide_pop_successs,alert,alert_confirm}