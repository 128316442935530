import { ref } from 'vue';
import { projectStorage,projectFirestore } from '../firebase/config';

function clearGPSMetadata(file, callback) {
  const reader = new FileReader();

  reader.onload = function(event) {
      const dataURL = event.target.result;
      const image = new Image();

      image.onload = function() {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);

          // Get the cleaned image data without EXIF metadata
          const cleanedDataURL = canvas.toDataURL("image/jpeg");

          // Convert dataURL back to File object for uploading
          fetch(cleanedDataURL)
              .then(res => res.blob())
              .then(blob => {
                  console.log(file);
                  const cleanedFile = new File([blob], file.name, {
                      type: "image/jpeg",
                      lastModified: new Date().getTime()
                  });
                  callback(cleanedFile);
              });
      };

      image.src = dataURL;
  };

  reader.readAsDataURL(file);
}

const upload_image_storage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
  
    // Path for firestore
    const filePath = ref(null);
  
    const uploadImage = async (file,path) => {
      filePath.value = `${path}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };


    // Method - 1
    const uploadResizeImage = async (file, path) => {
      filePath.value = `${path}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
      try {
        // upload the file
        await storageRef.put(file);
        // get the url of the uploaded image

        // const temp_url = await res.ref.getDownloadURL();
        // console.log(temp_url);

        // store the url in Firestore
        const docRef = projectFirestore.collection("resizes_images").doc(file.name);
        await docRef.set({
          path,
          resizedUrl: "" // it will be filled later by the cloud function
        });

        // Here you could add a snapshot listener on the document in Firestore.
        // Once the resizedUrl is added by the cloud function, you will be notified.


        
        console.log('before');
        url.value = await waitForUpdate(docRef,path)
        console.log('after');
       

      } catch (err) {
        console.log(err.message);
        error.value = err.message
      }
    };

    // Belong to Method - 1
    const waitForUpdate = (docRef,path) => {
      return new Promise((resolve, reject) => {
        const unsubscribe = docRef.onSnapshot(doc => {
          console.log('real time');
          const data = doc.data();
          if (data.resizedUrl && data.path == path) {
            resolve(data.resizedUrl);
            unsubscribe();
          }
        }, reject);
      });
    };

    // Method - 2
    const uploadResizeImage_method_2 = async(file,path) => {
      error.value = ''
      url.value =''
      filePath.value = `${path}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
      try {
        // Upload the image
        await storageRef.put(file).then((res) => console.log(res))
        // Wait for the resized URL
        const resizedPattern = /_375x375/; // adjust the pattern as per your naming convention
        url.value = await waitForResizedURL(filePath.value, resizedPattern);
      }catch(err){
        console.log(err.message);
        error.value = err.message
      }
    }

    // Method - 2
    async function waitForResizedURL(path, pattern, interval = 4000, timeout = 120000, startTime = Date.now()) {
      // Adjust the path to account for the resized file naming
      const resizedFilePath = path.replace(/(\.[\w\d_-]+)$/i, '_375x375$1');
      const storageRef = projectStorage.ref(resizedFilePath);
    
      try {
          const url = await storageRef.getDownloadURL();
          if (url.includes('_375x375')) {
              return url;
          } else {
              // If pattern doesn't match, check if we've timed out, if not, retry
              if (Date.now() - startTime > timeout) {
                  throw new Error('Timeout waiting for the resized URL.');
              }
              await new Promise(resolve => setTimeout(resolve, interval));
              return waitForResizedURL(path, pattern, interval, timeout, startTime);
          }
  
      } catch (err) {
          if (err.code === 'storage/object-not-found' && Date.now() - startTime <= timeout) {
              await new Promise(resolve => setTimeout(resolve, interval));
              return waitForResizedURL(path, pattern, interval, timeout, startTime);
          }
  
          throw err;
      }
    }
  
    
    // Delete the file
    const deleteImage = async path => {
      const storageRef = projectStorage.ref(path);
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };
    
    // Delete the file by url
    const delete_image_by_url = async url => {
      const storageRef = projectStorage.refFromURL(url);
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };


    return { error, url, filePath,deleteImage,delete_image_by_url,uploadImage,uploadResizeImage,uploadResizeImage_method_2, clearGPSMetadata};
};

export default upload_image_storage;