<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Rosman Logistic` : `Rosman Logistic` }}</template>
  </metainfo>
  <div class="update-alert" v-if="show_update_alert">
    <div v-loading="loading_last_update" class="update-text">
      <div class="update-title">
        <i class="material-icons" style="margin-left:10px; color:var(--yellow)">tips_and_updates</i>
        <p>קיים עדכון מערכת חדש</p>
      </div>
      <p class="updates-data">{{last_updates}}</p>
      <el-button type="success" style="width:98%; height:90px; margin-top: 5px;" class="wobble-hor-bottom" @click="update_system">עדכן עכשיו!</el-button>
    </div>
  </div>
  <template v-if="!nutral_route">
    <MainLoader v-if="loader"/>
    <MainNav/>
    <div class="main-grid" id="main-grid">
      <Support v-if="toggle_chat" @capture="capture_snapshot"/>
      <MainMenu/>
      <ShoppingCartBtn v-if="user"/>
      <router-view/>
    </div>
  </template>
  <template v-else>
    <main class="main-nutral-route">
      <router-view/>
    </main>
  </template>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed, onMounted} from '@vue/runtime-core';
import store from './store';
import MainLoader from './components/Loaders/MainLoader.vue';
import MainNav from './components/Bars/MainBar.vue';
import MainMenu from './components/Bars/MainMenu.vue';
import Support from './components/Support/Support.vue'
import ShoppingCartBtn from './components/ShoppingCart/ShoppingCartBtn.vue';
import  html2canvas from 'html2canvas'
import use_chat_messages_storage from './Methods/use_chat_messages_storage'
import { projectFirestore } from './firebase/config';
import { useMeta } from 'vue-meta'
import {nutral_rosman_routes} from './Methods/Utils'
import { useRoute } from 'vue-router';
import { watchEffect } from 'vue';

export default {
  components:{MainLoader,Support, MainNav, MainMenu, ShoppingCartBtn},
  setup(){
    
    
    useMeta({
      title: '',
      htmlAttrs: { lang: 'he', amp: true },
      meta:[
        {
          name:'description',
          content:`
            מערכת לניהול לוגיסטיקה - רוסמן, מערכת זו נועדה לשרת את עובדי הארגון ואת הלקוחות
          `
        },
        {
            name:'keywords',
            content:`
                רוסמן, רוסמן מרקט, מעדני רוסמן, rosman, ROSMAN, רוסמן לוגיסטיק
            `
        },
      ]
    })

    const {url,uploadImage} = use_chat_messages_storage()

    const toggle_chat = ref(computed(()=>{
      return store.state.show_support_chat
    }))
    
    const last_updates = ref('')
    //
    const show_update_alert = ref(false);

    const loading_last_update = ref(false)

    //
    let sw_detailes = null;
    //
    let refreshing = false;


    const user = ref(computed(() => {
      return store.getters.user
    }))

    const loader = ref(computed(() => {
      return store.getters.loader
    }))

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if(window.innerWidth<=600){
      store.state.platform = 'mobile'
    }
    else if(window.innerWidth>600 && window.innerWidth<1200){
      store.state.platform = 'tablet'
    }
    else{
      store.state.platform = 'desktop'
    }
    
    //
    document.addEventListener('serviceWorkerUpdateEvent', (e) => {
            sw_detailes = e.detail;
            show_update_alert.value = true;
    }, { once: true });
    //
    navigator.serviceWorker.addEventListener( 'controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    //
    const update_system = () => {
      show_update_alert.value = false;
      if(sw_detailes  || sw_detailes.waiting){
        sw_detailes.waiting.postMessage({type:'SKIP_WAITING'});
      }
    }

    const get_last_updates_from_db=async()=>{
            const doc = await projectFirestore.collection('Public').doc('Update_review').get()
            last_updates.value = doc.data().text
    }

    // for chat
    const capture_snapshot=()=>{
      html2canvas(document.getElementById('main-grid')).then(canvas => {
          canvas.toBlob(async(blob) => {
            await uploadImage(blob,user.value.display_name)
            console.log(url.value);
          })
          //console.log(canvas.toDataURL("image/png",1.0));
      });
    }

    onMounted(async() => {
      loading_last_update.value = true
      await get_last_updates_from_db()
      setTimeout(()=>{
        loading_last_update.value = false
      },1500)
      window.addEventListener('resize', () => {
        if(window.innerWidth<=600){
          store.state.platform = 'mobile'
        }
        else if(window.innerWidth>600 && window.innerWidth<1200){
          store.state.platform = 'tablet'
        }
        else{
          store.state.platform = 'desktop'
        }
        if(document.activeElement.type && document.activeElement.type === 'text'){
          console.log("Test input, no resize.")
        }
        else{
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      });
    })


    const route = useRoute(); 
    
    const nutral_route = ref(false)
    watchEffect(() => {
      if (route) {
        console.log(route.path);
        nutral_route.value = nutral_rosman_routes(route.path)
      }
    })

    return{
      loader, show_update_alert, update_system, user,last_updates,loading_last_update,toggle_chat,capture_snapshot,nutral_route
    }
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
:root{
  --main: #161D31;
  --secondary: #283046;
  --alert-purple: rgba(115,103,240,.12);
  --purple: #7367f0;
  --indigo: #6610f2;
  --danger: #ea5455;
  --warning: #f5953b;
  --success: #28c76f;
  --light: #f6f6f6;
  --yellow: #ffc107;
  --blue: #3d36a1;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-weight: 300;
  direction: rtl;
  -webkit-tap-highlight-color: transparent;
}
a{
  text-decoration: none;
}
.main-grid,.main-nutral-route{
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  background: var(--main);
  direction: rtl;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
}
.main-nutral-route{
  height: calc(var(--vh, 1vh) * 100);
  background: #fff;
}
.update-alert{
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px 0 5px;
  box-shadow: 0 0 10px black;
}
.update-title{
  width: 100%;
  height: 50px;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: var(--success);
  color: white;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.753);
  text-align: center;
  padding: 3px;
}
.update-text{
  border-radius: 10px;
  width: 360px;
  max-width: calc(100% - 10px);
  height: 500px;
  max-height: 80%;
  background: #fff;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.562);
}
.updates-data{
  width: 100%;
  height: calc(100% - 150px);
  overflow-y: auto;
  padding: 5px;
  font-size: 18px;
}
::-webkit-scrollbar{
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 7px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(var(--main), var(--secondary)) ;
  border-radius: 5px;
  width: 5px;
}
::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px; 
}
::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}

@media only screen and (max-width: 780px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}

::global(.swal2-container) {
      z-index: 100000 !important; 
}

</style>
