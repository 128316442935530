import {projectFirestore} from '../firebase/config'
import store from '../store'

export const get_clients_list_from_db = async()=>{
    const docs = await projectFirestore.collection('Clients').get()
    return docs.docs.map(doc=>{
        return{
            label:doc.data().name,
            value:doc.data().makat
        }
    })
}

export const save_data_in_db = async(data)=>{
    const doc =  projectFirestore.collection('Promotion').doc('type')
    .collection('clients_propmotions').doc()
    data.uid = doc.id
    await doc.set(data)
}

export const update_data_in_db = async(data)=>{
     await projectFirestore.collection('Promotion').doc('type')
    .collection('clients_propmotions').doc(data.uid)
    .set(data,{merge:true})
}

export const get_clients_propmotions_from_db = async()=>{
    const docs = await projectFirestore.collection('Promotion').doc('type')
    .collection('clients_propmotions').get()
    return docs.docs.map(doc=>doc.data())
}

export const delete_promotion_from_db = async(record)=>{
    await projectFirestore.collection('Promotion').doc('type')
    .collection('clients_propmotions').doc(record.uid).delete()
}

export const get_valids_promotions = async(valid)=>{
    const docs = await projectFirestore.collection('Promotion').doc('type')
    .collection('clients_propmotions').get()
    const current_date = new Date()
    current_date.setHours(0,0,0,0)
    return docs.docs.map(doc=>doc.data()).filter(doc=>{
        const from_date = new Date(doc.from_date.seconds * 1000)
        from_date.setHours(0,0,0,0)
        const to_date = new Date(doc.to_date.seconds * 1000)
        to_date.setHours(0,0,0,0)

        if(valid){
            if(from_date <= current_date && current_date <= to_date) return doc
        }else{
            if(!(from_date <= current_date && current_date <= to_date)) return doc
        }
    })
}

export const after_client_implement_gift = async(promotion)=>{
    const client = store.state.user.client
    if(promotion.client_type=='בחירת לקוחות'){
        const index = promotion.clients.findIndex(_client=>_client==client)
        console.log(index);
        index!=-1 && promotion.clients.splice(index,1)
        await projectFirestore.collection('Promotion').doc('type')
        .collection('clients_propmotions').doc(promotion.uid)
        .set({
            clients:promotion.clients
        },{merge:true})
    }
    if(promotion.client_type=='כל הלקוחות'){
        promotion.ignore.push(client)
        await projectFirestore.collection('Promotion').doc('type')
        .collection('clients_propmotions').doc(promotion.uid)
        .set({
            ignore:promotion.ignore
        },{merge:true})
    }
    const p_index = store.state.client.promotions.findIndex(_promotion=>_promotion.uid==promotion.uid)
    p_index!=-1 && store.state.client.promotions.splice(p_index,1)
}

export const update_client_fulfillment = async(promotion,object_data)=>{
    
    const clone_fulfillment_array = [...promotion.fulfillments]
    
    clone_fulfillment_array.push(object_data)
    
    await projectFirestore.collection('Promotion').doc('type')
        .collection('clients_propmotions').doc(promotion.uid)
        .set({
            fulfillments:clone_fulfillment_array
        },{merge:true})
}

export const fulfillment_agents_list_per_promotion = (fulfillments)=>{
    const data = []
    const agents_counter = new Map()
    for(const f of fulfillments){
        if(agents_counter.has(f.agent)){
            agents_counter.set(f.agent,{
                agent_name:agents_counter.get(f.agent).agent_name,
                count:agents_counter.get(f.agent).count+1
            })
        }else{
            agents_counter.set(f.agent,{
                agent_name:f.agent_name,
                count:1
            })
        }
    }
    for (const key of agents_counter.keys()) {
        data.push({
            agent:key,
            agent_name:agents_counter.get(key).agent_name,
            count:agents_counter.get(key).count
        })
    }
    return data
}

export const get_item_from_catalog = async(makat)=>{
    const doc = await projectFirestore.collection('Products').doc(makat).get()
    if(doc.exists) return doc.data()
    return null
}

