<template>
    <div class="user-mini" v-if="user">
        <div class="log-out" @click="log_out">
            <i class="material-icons">logout</i>
        </div>
        <div class="user-image">
            <img :src="user.profile_image" alt="">
        </div>
        <div class="user-data">
            <p style="color: var(--yellow); font-size:18px;">{{user.name}}</p>
            <p>{{user.email}}</p>
            <p v-if="user.company">{{user.company}}</p>
            <p v-else> </p>
            <div class="btns">
                <div class="btn">
                    <i class="material-icons">supervised_user_circle</i>
                    <p>פרופיל</p>
                </div>
                <div class="btn">
                    <i class="material-icons">email</i>
                    <p>הודעות</p>
                </div>
            </div>
        </div>
    </div>
    <div class="no-user" v-if="!user">
        <p class="hello">שלום 👋</p>
        <el-button type="primary" id="log-in" @click="go_login">התחבר</el-button>
        <p v-if="false" class="new-user" @click="go_reg">צור משתמש חדש</p>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import store from '../../store'
import router from '../../router'
import { projectAuth, projectFirestore, projectFunctions } from '../../firebase/config'
import {slide_pop_successs} from '../../Methods/Msgs';
export default {
setup(){
    const user = ref(computed(() => {
        return store.getters.user
    }))

    const go_reg = () => {
        if(!user.value){
            router.push('/register');
            store.dispatch('toggle_menu');
        }
    }

    const go_login = () => {
        if(!user.value){
            router.push('/login');
            store.dispatch('toggle_menu');
        }
    }
    
    const log_out = async () => {
        if(store.state.show_support_chat){
            await store.dispatch('terminate_user_chat')
        }
        const local_data = JSON.parse(localStorage.getItem('support_chat'))
        if(local_data){
            localStorage.removeItem('support_chat')
        }
        router.replace('/');
        await projectFunctions.httpsCallable("visibility_state")({state:'logged out'})
        await projectAuth.signOut();
        store.dispatch('toggle_menu')
        slide_pop_successs('נותקת בהצלחה!')
    }

    return{
        user, go_reg, log_out, go_login
    }
}
}
</script>

<style scoped>
.user-mini{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-image{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-image img{
    max-width: 90%;
    max-height: 90%;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.295);
}
.user-data{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    padding: 10px;
}
.user-data p {
    width: 100%;
    text-align: center;
    line-height: calc(100px / 5);
    color: var(--light);
}
.btns{
    width: 100%;
    height: calc(calc(100px / 5) * 2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    background: var(--alert-purple);
    border-radius: 5px;
}
.btn{
    width: calc(45%);
    height: 95%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.btn p{
    margin-right: 5px;
    text-align: start;
}
.btn i{
    color: var(--warning);
}
.no-user{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.hello{
    width: 100%;
    text-align: center;
    color: var(--light);
    font-size: 18px;
    margin-bottom: 5px;
}
#log-in{
    font-size: 18px;
}
.new-user{
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: var(--success);
    cursor: pointer;
}
.log-out{
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 1;
    cursor: pointer;
    color: var(--danger);
}
</style>